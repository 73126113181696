<!--
 * @Author: Axs <i@axs.email>
 * @Date: 2021-03-25 14:51:40
 * @LastEditors: Axs <i@axs.email>
 * @LastEditTime: 2021-03-29 21:22:15
 * @Description: 
 * @FilePath: /src/views/pay/wechat.vue
-->
<template>
    <div class="page">
        <!-- <div @click="payBtn()"
             style="width: 200px;margin: 0 auto;text-align:center;height: 40px;line-height: 40px;
             background: yellow;">继续支付</div> -->
    </div>
</template>
<script>
    import pay from 'pay';
    import { QZ_PAY_TYPE } from 'constant';
    const { ALIPAY_H5, WXPAY_H5, WXPAY_MIN, WXPAY_BROWSER, OTHERPAY, TTPAY_MIN } = QZ_PAY_TYPE;
    export default {
        data() {
            return {
                orderType: 0,
                orderId: '',
                orderNo: '',
                returnUrl: '',
            }
        },
        created() {
            this.orderType = this.$route.query.orderType;
            this.orderId = this.$route.query.orderId;
            this.orderNo = this.$route.query.orderNo;
            this.returnUrl = this.$route.query.returnUrl;

            setTimeout(() => {
                this.awakenPay();    
            }, 200);
        },
        methods: {
            awakenPay() {
                pay({
                    orderType: this.orderType,
                    orderId: this.orderId,
                    orderNo: this.orderNo,
                    returnUrl: this.returnUrl,
                    payType: WXPAY_H5
                }).then(res => {
                    if (res.payType == WXPAY_BROWSER) {
                        if (res.success) {
                            window.location.replace(this.returnUrl);
                        }
                        else {
                            this.$confirm.open({
                                title: '温馨提示',
                                message: '确定取消支付嘛？',
                                confirmText: '重新支付',
                                cancelText: '忍痛取消',
                                success: () => {
                                    this.awakenPay();
                                },
                                error: () => {
                                    window.location.replace(this.returnUrl);
                                }
                            })
                        }
                    }
                })
            }
        }
    }
</script>
